import {Tooltip} from "antd";
import {dateFormatter} from "./index";
import React from "react";

export const getTournamentColumns = (userBookmakers, userBookmaker = '') => {
    return getColumns('TOURNAMENT', userBookmakers, userBookmaker);
}

export const getReferenceColumns = (userBookmakers, userBookmaker = '') => {
    return getColumns('REFERENCE', userBookmakers, userBookmaker);
}

export const getMarketColumns = (userBookmakers, userBookmaker = '') => {
    return getColumns('MARKET', userBookmakers, userBookmaker);
}

const getColumns = (column, userBookmakers, userBookmaker) => {
    const marketsSets = [column];
    if (userBookmaker) {
        marketsSets.push(userBookmaker)
    }
    userBookmakers
        .filter(bookmaker => bookmaker !== userBookmaker)
        .forEach(bookmaker => marketsSets.push(bookmaker))

    return marketsSets
        .map((key, index) => ({
            width: '90%',
            title: (
                <span className={key.toLowerCase() === userBookmaker ? 'text-light' : 'text-secondary-emphasis'}>{key.toUpperCase()}</span>
            ),
            dataIndex: key.toUpperCase(),
            render: (text) => (
                index !== 0 ? (
                    <Tooltip title={text?.[1] ? `Updated at: ${dateFormatter(text[1])}` : ""}>
                        <span>{text?.[0] || ""}</span>
                    </Tooltip>
                ) : (
                    <span>{text || ""}</span>
                )
            ),
        }))
}
