import {Table} from 'antd'
import React, {useMemo} from 'react'
import {formatNumber, referenceLive} from '../../../utils'
import {bookmakerLiveServices} from "../../../api"
import {getTournamentColumns} from "../../../utils/common-functions/margin"

export const TournamentBookmakersLiveCombination = ({
                                                        selectedMarket,
                                                        selectedReference,
                                                        userBookmakers,
                                                        userBookmaker,
                                                        selectedSport,
                                                        fixtures
                                                    }) => {
    const [data, setData] = React.useState([])
    const [user] = React.useState(localStorage.getItem('token'))

    const columns = useMemo(() => {
        return getTournamentColumns(userBookmakers, userBookmaker)
    }, [userBookmakers, userBookmaker])

    React.useEffect(() => {
        (async () => {
            const response = await bookmakerLiveServices.getMarketReference(user, selectedMarket, selectedReference)
            setData(response.data
                .filter(item => item.sportId.toString() === selectedSport) // Filtra gli sport corretti
                .map(item => ({
                    _id: item._id,
                    bookmakers: item.bookmakers,
                    tournament: fixtures?.[item._id]?.tournament || '',
                    category: fixtures?.[item._id]?.category || '',
                    categoryId: fixtures?.[item._id]?.categoryId,
                    sportId: fixtures?.[item._id]?.sportId || -1,

                }))
                .sort((a, b) => {
                    const sorted = a.category.localeCompare(b.category)
                    return sorted !== 0 ? sorted : a.tournament.localeCompare(b.tournament)
                })
                .flatMap(item => {
                    return Object.entries(item.bookmakers)
                        .filter(([_, markets]) => markets[selectedMarket]) // Filtra i bookmaker con il mercato selezionato
                        .flatMap(() => {
                            return Object.keys(referenceLive)
                                .filter(weekDay => selectedReference.toLowerCase() === weekDay.toLowerCase()) // Filtra il giorno corretto
                                .map(day => {
                                    const tournamentName = `${item.category} - ${item.tournament} ` +
                                        (item.eurobetId ? `(${item.eurobetId})` : '')

                                    return userBookmakers.reduce((acc, bookM) => {
                                        const marketData = item.bookmakers?.[bookM]?.[selectedMarket]?.[day]
                                        acc[bookM.toUpperCase()] = marketData
                                            ? [formatNumber(marketData.value, 1), marketData.data]
                                            : ["", ""]
                                        return acc
                                    }, {TOURNAMENT: tournamentName})
                                })
                        })
                }))
        })()
    }, [user, selectedReference, selectedMarket])

    const dataSource = React.useMemo(() => data, [data])

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['20', '50', '100']}}
        />
    )
}