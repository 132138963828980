import {Button, Table} from 'antd'
import React, {useMemo} from 'react'
import {formatNumber, marketsLiveMap, marketsMap} from '../../../utils'
import {CSVLink} from 'react-csv'
import {bookmakerLiveServices} from "../../../api"
import {getMarketColumns} from "../../../utils/common-functions/margin"

export const MarketsBookmakersLiveCombination = ({
                                                     selectedTournamentId,
                                                     selectedReference,
                                                     userBookmakers,
                                                     userBookmaker,
                                                     fixtures
                                                 }) => {
    const [data, setData] = React.useState([])
    const [user] = React.useState(localStorage.getItem('token'))

    const columns = useMemo(() => {
        return getMarketColumns(userBookmakers, userBookmaker)
    }, [userBookmakers, userBookmaker])

    React.useEffect(() => {
        async function loadData() {
            const response = await bookmakerLiveServices.getTournamentReference(user, selectedTournamentId, selectedReference)
            const marketsBookmakers = {}

            response.data
                .filter(i => i._id === selectedTournamentId)
                .map(item => {
                    const fixture = fixtures?.[item._id] || {}
                    return {
                        _id: item._id,
                        bookmakers: item.bookmakers,
                        tournament: fixture.tournament || '',
                        category: fixture.category || '',
                        categoryId: fixture.categoryId || 0,
                        date: fixture.date || 0,
                        sport: fixture.sport || '',
                        sportId: fixture.sportId || 0,
                    }
                })
                .forEach((tournament) => {

                    userBookmakers.forEach((bookmaker) => {

                        const markets = tournament.bookmakers[bookmaker]
                        if (!markets) return

                        Object.keys(marketsLiveMap)
                            .forEach((market) => {

                                if (!marketsBookmakers[market]) {
                                    marketsBookmakers[market] = {
                                        MARKET: marketsMap[market] || market,
                                    }
                                }

                                Object.keys(markets[market] || {})
                                    .filter(reference => selectedReference.toLowerCase() === reference)
                                    .forEach((reference) => {

                                        const bookmakerData = markets[market]?.[reference]
                                        marketsBookmakers[market][bookmaker.toUpperCase()] = [
                                            bookmakerData ? formatNumber(bookmakerData.value, 1) : "",
                                            bookmakerData?.data || ""
                                        ]

                                    })

                            })
                    })
                })

            setData(Object.values(marketsBookmakers))
        }

        loadData()
    }, [user, selectedReference, selectedTournamentId])

    const dataSource = React.useMemo(() => data, [data])
    return (
        <>
            <div className='w-100 d-flex justify-content-center'>
                <CSVLink data={dataSource}
                         filename={`${selectedTournamentId}-${selectedReference}-${new Date(Date.now()).toLocaleTimeString()}-${new Date(Date.now()).toLocaleDateString()}.csv`}>
                    <Button className='sidebet-export-button ml-2'>
                        Export as CSV
                    </Button>
                </CSVLink>
            </div>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={{defaultPageSize: 20}}
            />
        </>
    )
}
