import {Button, ConfigProvider, theme} from 'antd';
import React, {useState} from 'react';
import '../../App.css';
import {Link} from "react-router-dom";

export const Home = () => {

    const [products] = useState(JSON.parse(localStorage.getItem('products') || "{}"))

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("bookmaker")
        localStorage.removeItem("products")
        localStorage.removeItem("user")
        window.location.reload();
    }

    return (
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
                token: {
                    colorBgContainer: "#283038"
                }
            }}>
            <div className="container" data-bs-theme="dark">
                <div className='d-flex'>
                    <div className='w-25 d-flex'>
                        <h3 className="col-sm-12 col-md-5 px-2 sidebet-title">Home</h3></div>
                    <div className='w-75'>
                        <Link
                            className='sidebet-nav-item float-right'
                            onClick={() => {
                                handleLogout()
                            }}
                        >Logout
                        </Link>
                        <Link
                            to='/fixtures'
                            disabled={!Object.keys(products).includes('fixtures')}
                            className='sidebet-nav-item float-right mx-1'
                        >Fixtures Report
                        </Link>
                        <Link
                            to='/oddvariations'
                            disabled={!Object.keys(products).includes('oddvariations')}
                            className='sidebet-nav-item float-right mx-1'
                        >Odd Variations Report
                        </Link>
                        <Link
                            to='/margin'
                            disabled={!Object.keys(products).includes('margin')}
                            className='sidebet-nav-item float-right mx-1'
                        >Margin Report
                        </Link>
                    </div>
                </div>
            </div>
        </ConfigProvider>
    );
};
