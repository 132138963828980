import {Button, Table} from 'antd'
import React, {useMemo} from 'react'
import {formatNumber, referenceLive} from '../../../utils'
import {CSVLink} from 'react-csv'
import {bookmakerLiveServices} from "../../../api"
import {getReferenceColumns} from "../../../utils/common-functions/margin"

export const TournamentsMarketsLiveCombination = ({
                                                      selectedTournamentId,
                                                      selectedMarket,
                                                      userBookmakers,
                                                      userBookmaker,
                                                      fixtures
                                                  }) => {
    const [data, setData] = React.useState([])
    const [user] = React.useState(localStorage.getItem('token'))

    const columns = useMemo(() => {
        return getReferenceColumns(userBookmakers, userBookmaker)
    }, [userBookmakers, userBookmaker])

    React.useEffect(() => {

        async function loadData() {
            const response = await bookmakerLiveServices.getTournamentMarket(user, selectedTournamentId, selectedMarket)

            if (response.data.length === 0) return

            const data = response.data
                .map(item => ({
                    _id: item._id,
                    bookmakers: item.bookmakers,
                    tournament: fixtures?.[item._id]?.tournament || '',
                    category: fixtures?.[item._id]?.category || '',
                    categoryId: fixtures?.[item._id]?.categoryId || 0,
                    date: fixtures?.[item._id]?.date || 0,
                    sport: fixtures?.[item._id]?.sport || '',
                    sportId: fixtures?.[item._id]?.sportId || 0,

                }))
                .sort((a, b) => {
                    const sorted = a.category.localeCompare(b.category)
                    return sorted !== 0 ? sorted : a.tournament.localeCompare(b.tournament)
                })

            const referenceBookmakers = []
            const selectedTournament = data.find(
                (i) => i._id === selectedTournamentId
            )

            const bookmakers = Object.keys(selectedTournament.bookmakers)

            bookmakers.forEach((bookmaker) => {
                const market = selectedTournament.bookmakers[bookmaker]
                const marketKeys = Object.keys(market)

                if (marketKeys.length === 0) return false

                const selectedMarketName = (marketKeys[0].includes('Basket') || marketKeys[0].includes('Tennis'))
                    ? selectedMarket
                    : selectedMarket.toUpperCase()

                if (!market[selectedMarketName]) return false

                Object.keys(market[selectedMarketName]).forEach((weekDay) => {
                    const ref = referenceLive[weekDay]
                    const formattedReference = ref.charAt(0).toUpperCase() + ref.slice(1)

                    const referenceBookmakersObj = userBookmakers.reduce((acc, bookM) => {
                        const currentMarket = selectedTournament.bookmakers[bookM] || {}
                        const marketData = currentMarket[selectedMarketName]?.[weekDay]

                        acc[bookM.toUpperCase()] = marketData
                            ? [formatNumber(marketData.value, 1), marketData.data]
                            : ["", ""]

                        return acc
                    }, {REFERENCE: formattedReference})

                    referenceBookmakers.push(referenceBookmakersObj)
                })
            })

            setData(referenceBookmakers)

        }

        loadData()
    }, [user, selectedMarket, selectedTournamentId])

    const dataSource = React.useMemo(() => data, [data])
    return (
        <>
            <div className='w-100 d-flex justify-content-center'>
                <CSVLink data={dataSource}
                         filename={`${selectedTournamentId}-${selectedMarket}-${new Date(Date.now()).toLocaleTimeString()}-${new Date(Date.now()).toLocaleDateString()}.csv`}>
                    <Button className='sidebet-export-button ml-2'>
                        Export as CSV
                    </Button>
                </CSVLink>
            </div>
            <Table
                columns={columns}
                dataSource={dataSource}
            />
        </>
    )
}
