export const reference = {
    last: 'Last',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    '0': 'Matchday',
    '1': 'Matchday + 1',
    '2': 'Matchday + 2',
    '3': 'Matchday + 3',
    '4': 'Matchday + 4',
    '5': 'Matchday >= 5',
};

export const referenceLive = {
    0: '0-10',
    1: '11-20',
    2: '21-30',
    3: '31-40',
    4: '41-50',
    5: '51-60',
    6: '61-70',
    7: '71-80',
    8: '81-90',
    9: '90+'
};

export const sports = {
    '0': 'All sports',
    '1': 'Soccer',
    '2': 'Basket',
    '5': 'Tennis'
}
export const marketsMap = {
    '1X2': '1X2',
    'OU25': 'Over/Under 2.5',
    'BTTS': 'Both Teams To Score',
    '1X2HT1': '1X2 - 1st Half',
    'HT/FT': 'Halftime/Fulltime',
    '1X2&OU': '1X2 & Over/Under',
    'OUHOME': 'Over/Under Home',
    'OUAWAY': 'Over/Under Away',
    '1X2HANDICAP': '1X2 Handicap',
    '1X2&OUHT1': '1X2 & O/U - 1st Half',
    '1X2&OUHT2': '1X2 & O/U - 2nd Half',
    'PASSAGGIOTURNO': 'Passaggio Turno',
    '1X2+MULTIGOAL': '1X2 & Multigoal',
    '1X2&BTTS': '1X2 & BTTS',
    'BTTSHT1': 'Both Teams To Score - 1st Half',
    'BTTSHT2': 'Both Teams To Score - 2nd Half',
    'CS': 'Correct Score',
    'TennisO/U': 'Over / Under',
    'TennisHND': 'Handicap',
    'TennisHH': 'Head to Head',
    'TennisSetBetting': 'Set Betting',
    'TennisHH1set': 'Head to Head 1st Set',
    'BasketO/U': 'Over / Under',
    'BasketHND': 'Handicap',
    'BasketHH': 'Head to Head'
};

export const marketsLiveMap = {
    '1X2': '1X2',
    '1X2HT1': '1X2 - 1st Half',
    'CS': 'Correct Score',
    'NG': 'Next Goal'
}

export const Markets = {
    "1": {
        id: "1",
        name: "1X2",
        outcomes: {"1": {name: "1"}, "2": {name: "X"}, "3": {name: "2"}},
        sport: 'Soccer',
        marketType: 'Match',
    },
    "2": {
        id: "2",
        name: "O/U",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        sport: 'Soccer',
        marketType: 'Match',
    },
    "3": {
        id: "3",
        name: "H/H",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        sport: 'Basket',
        marketType: 'Match',
    },
    "4": {
        id: "4",
        name: "H/H",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        sport: 'Tennis',
        marketType: 'Match',
    },
    "9": {
        id: "9",
        name: "BTTS",
        outcomes: {"2": {name: "Yes"}, "3": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Match',
    },

    "11": {
        id: "11",
        name: "O/U 1.5",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        sport: 'Soccer',
        marketType: 'Match',
    },
    "12": {
        id: "12",
        name: "O/U 3.5",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        sport: 'Soccer',
        marketType: 'Match',
    },
    "13": {
        id: "13",
        name: "O/U 4.5",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        sport: 'Soccer',
        marketType: 'Match',
    },
    "14": {
        id: "14",
        name: "1X2 HT",
        outcomes: {"1": {name: "1"}, "2": {name: "X"}, "3": {name: "2"}},
        sport: 'Soccer',
        marketType: 'Match',
    },
    "15": {
        id: "15",
        name: "O/U 1.5 HT",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        sport: 'Soccer',
        marketType: 'Match',
    },
    "Any": {
        id: "Any",
        name: "Anytime",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "1st": {
        id: "1st",
        name: "First",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "2o+": {
        id: "2o+",
        name: "2orMore",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "3o+": {
        id: "3o+",
        name: "3orMore",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "AnyHT": {
        id: "AnyHT",
        name: "AnyHT",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "BHlv": {
        id: "BHlv",
        name: "BothHalves",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "Asst": {
        id: "Asst",
        name: "Assist",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "Cd": {
        id: "Cd",
        name: "Card",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "1stCd": {
        id: "1stCd",
        name: "FirstCard",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "1SOT": {
        id: "1SOT",
        name: "1 TP",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "2SOT": {
        id: "2SOT",
        name: "2 TP",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "3SOT": {
        id: "3SOT",
        name: "3 TP",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "4SOT": {
        id: "4SOT",
        name: "4 TP",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "5SOT": {
        id: "5SOT",
        name: "5 TP",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "1TS": {
        id: "1TS",
        name: "1 TT",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "2TS": {
        id: "2TS",
        name: "2 TT",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "3TS": {
        id: "3TS",
        name: "3 TT",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "4TS": {
        id: "4TS",
        name: "4 TT",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "5TS": {
        id: "5TS",
        name: "5 TT",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "6TS": {
        id: "6TS",
        name: "6 TT",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "7TS": {
        id: "7TS",
        name: "7 TT",
        outcomes: {"1": {name: "Yes"}, "2": {name: "No"}},
        sport: 'Soccer',
        marketType: 'Players'
    },
    "Points": {
        id: "Points",
        name: "Points",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        sport: 'Basket',
        marketType: 'Players'
    },
    "Assists": {
        id: "Assists",
        name: "Assists",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        sport: 'Basket',
        marketType: 'Players'
    },
    "Rebounds": {
        id: "Rebounds",
        name: "Rebounds",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        sport: 'Basket',
        marketType: 'Players'
    },
    "3Points": {
        id: "3Points",
        name: "3Points",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        sport: 'Basket',
        marketType: 'Players'
    },
    "Steals": {
        id: "Steals",
        name: "Steals",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        sport: 'Basket',
        marketType: 'Players'
    },
    "Turnovers": {
        id: "Turnovers",
        name: "Turnovers",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        sport: 'Basket',
        marketType: 'Players'
    },
    "Blocks": {
        id: "Blocks",
        name: "Blocks",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        sport: 'Basket',
        marketType: 'Players'
    },
}
